import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지파이브, MVNO(알뜰폰) 5G 10GB+ 유심 요금제 출시…’국내 최저가’ 월 2만 7천원",
  "| LTE 유심요금제도 제휴카드나 계좌개설 조건 없이 합리적으로… “소비자 선택권 강화”",
]

const content = () => (
  <div>
    <style>
      {
        "\
          .pressDetailPage .pressDetailBody .contentArea .descImage.promotion {\
            margin: auto;\
            width: 330px;\
          }\
        "
      }
    </style>
    <div className="descImage promotion">
      <div>
        <img src="/images/press/1/avMhRNiP.jpg" alt="핀다이렉트샵 유심요금제 런칭 프로모션" />
      </div>
      <div className="desc">
        △ ㈜스테이지파이브(공동대표 이제욱 ㆍ 서상원, 이하 스테이지파이브)가
        <br />
        2일(화) 초저렴한 MVNO(알뜰폰) 5G 유심요금제 및 LTE 유심요금제를 개시했다.
        <br />
        비대면 통신 가입 플랫폼 ‘핀다이렉트샵’을 통해 판매되는 온라인 전용 상품이다
      </div>
    </div>
    <p>
      <br />
      ㈜스테이지파이브(공동대표 이제욱 ㆍ 서상원, 이하 스테이지파이브)가 2일(화) 초저렴한 MVNO(알뜰폰) 5G 유심 요금제 및 LTE 유심
      요금제인 ‘핀다이렉트 유심 요금제’를 개시했다. 비대면 통신 가입 플랫폼 ‘핀다이렉트샵’을 통해 판매되는 온라인 전용 상품이다.
      <br />
      <br />
      지난해 9월 출시된 ‘핀다이렉트샵’이 MNO(이동통신)요금제에 이어 이번 MVNO(알뜰폰)요금제를 포함하면서 스테이지파이브의 사업
      범위가 확장된 셈이다.
      <br />
      <br />
      이로써 핀다이렉트샵 고객은 최신 스마트폰에 맞춤형 매칭되는 MNO 요금제 뿐 아니라 5G 및 LTE를 포함하는 MVNO 요금제까지 다양한
      선택지를 두고 직접 통신 가입을 할 수 있게 됐다.
    </p>
    <p>
      <span className="bold">| 초저렴한 알뜰폰 5G 요금제 … 오픈 기념 프로모션 진행</span>
      <br />
      <br />
      {`스테이지파이브가 출시한 <5G 슬림 10G+> 요금제는 핀다이렉트샵 MVNO 전용 5G 요금제이다.(이하 ‘유심요금제’) 해당 유심요금제는 월 기본요금 2만 7천원으로 데이터 10GB를 제공하며, 음성ㆍ문자를 한도 없이 제공한다. 기본 데이터를 소진하면 1Mbps 속도로 무제한 사용 가능하다.`}
      <br />
      <br />
      위 유심요금제 할인을 위해 고객에게 제시하는 별도 조건도 없다. 통상 제휴카드 가입이나 계좌개설과 같은 부가 서비스를 신청해야
      받을 수 있었던 할인과 달리, 언택트로 가입만 간편하게 진행하면 된다. 가입비나 약정도 없다. 본 기본요금은 오픈 기념
      프로모션으로, 이후에는 3만 7천원의 합리적 가격으로 제공한다.
      <br />
      <br />
      스테이지파이브는 새로운 LTE 유심요금제 3종도 최저 가격에 선보인다.
    </p>
    <div className="descImage fare">
      <div>
        <img src="/images/press/1/bnJRPDgT.jpg" alt="요금제 프로모션 안내 이미지" />
      </div>
      <div className="desc">
        △ ㈜스테이지파이브가 2일(화) 초저렴한 MVNO(알뜰폰) 5G 및 LTE 유심요금제를 개시했다.
        <br />
        사진은 관련 요금제 프로모션 안내 이미지 일부
      </div>
    </div>
    <br />
    <p>
      {`<완전자유 11GB+>는 월 23,330원에 데이터 11GB를 제공하며, 소진 시 일 2GB를 제공, 이후에는 3Mbps 속도로 무제한 이용할 수 있다. 음성ㆍ문자 역시 무제한으로 제공한다. 3월 가입자에 한해 가입 후 1년간 월 100GB 추가 데이터를 지원하는 프로모션도 함께 진행된다.`}
      <br />
      <br />
      {`<데이터안심 15GB+>는 월 21,000원에 데이터 15GB , 소진 시 3Mbps 속도로 지원한다. 음성 100건(KT망 내 1회선 무제한), 문자 100건이 제공된다. 3월 가입자에게는 가입 후 1년간 월 50GB의 추가 데이터가 제공되는 프로모션 혜택이 있다.`}
      <br />
      <br />
      {`<완전자유 3GB+>는 월 19,100원에 데이터 3GB, 소진 시 1Mbps 속도로 지원한다. 음성 및 문자 무제한(영상/부가통화는 300분) 제공한다. 3월 가입자에게는 가입 후부터 해지 시까지 월 4GB를 추가 제공한다.`}
      <br />
      <br />
      {`LTE 유심요금 3종의 월 기본요금 역시 5개월 프로모션가로, 이후에는 ▲<완전자유 11GB+> 33,330원 ▲<데이터안심 15GB+> 28,000원 ▲<완전자유 3GB+> 26,100원에 동일 조건으로 이용 가능하다.`}
    </p>
    <br />
    <br />
    <br />
    <p>
      <span className="bold">| 카카오톡으로 가입ㆍ개통 한 번에, 요금제 쉽게 찾을 수 있는 ‘핀다이렉트샵’ 웹 오픈</span>
      <br />
      <br />
      한편, 스테이지파이브는 고객이 더욱 편리하고 쉽게 요금제를 비교하고 선택할 수 있도록 ‘핀다이렉트샵’ 웹
      페이지(http://pindirectshop.kr/usimplan_intro)를 오픈했다. 스테이지파이브는 지난 2년 간 ‘핀다이렉트샵’을 통해 누적 방문자
      수가 20만 명(21년 1월 기준)을 상회하는 실적을 냈다.
      <br />
      <br />
      스테이지파이브 이제욱ㆍ서상원 공동대표는 “스테이지파이브는 통신 사업을 넘어선 ICT 기술로 고객 접점을 높이고, 모든 이에게
      편리하고 합리적이며 새로운 통신 기술 서비스를 선보이고자 노력할 것”이라면서 “앞으로도 새롭고 다양한 디지털 서비스로 변화를
      만들어 나가겠다”고 전했다.
      <br />
    </p>
  </div>
)

const press1 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 3월 2일 즉시 배포 가능"
      pageInfo="*총 2매/ 첨부: 사진 5매"
      title="알뜰폰 5G유심도 핀다이렉트샵서 최저가로 ‘비대면’ 가입하세요"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press1
